import React, { useEffect, useContext, memo } from "react";
import { Route, Redirect } from "react-router-dom";
import AuthContext from "../../Context/Auth/AuthContext";

const RutaPrivada = ({ component: Component, ...props }) => {
  const { autenticado, cargando, usuarioAutenticado, usuario } = useContext(
    AuthContext
  );

  useEffect(() => {
    if (!usuario) {
      usuarioAutenticado();
    }
    // eslint-disable-next-line
  }, []);
  return (
    <Route
      {...props}
      render={(props) =>
        !autenticado && !cargando ? (
          <Redirect to="/" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default memo(RutaPrivada);
